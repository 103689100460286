import './header.scss';
import { Link } from 'react-router-dom';
import content from '../../Localization/content';

import Logo from '../../assets/images/logo.png';

function Header({ lang, setLang }) {
	return (
		<>
			<header className='header' id='header'>
				<div className='container'>
					<div className='header__box'>
						<div className='header__logo-box'>
							<Link to={'/'}>
								<img
									className='logo'
									src={Logo}
									alt='logo'
									width={80}
									height={120}
								/>
							</Link>
						</div>

						<nav className='header__nav desktop'>
							<ul className='header__nav__list'>
								<li className='header__nav__item'>
									<a className='header__nav__link' href={'/'}>
										{content[lang].header.welcome}
									</a>
								</li>
								<li className='header__nav__item'>
									<a className='header__nav__link' href={'/#about'}>
										{content[lang].header.about}
									</a>
								</li>
								<li className='header__nav__item'>
									<a className='header__nav__link' href={'/#contact'}>
										{content[lang].header.contact}
									</a>
								</li>
							</ul>
						</nav>

						<ul className='header__nav__list desktop'>
							<li className='header__nav__item'>
								<select
									className='header__select'
									defaultValue={lang}
									onChange={(e) => {
										window.localStorage.setItem(
											'lang',
											JSON.stringify(e.target.value),
										);
										setLang(e.target.value);
									}}>
									{/* <option value='en'>EN</option> */}
									<option value='uz'>UZ</option>
									<option value='ru'>RU</option>
								</select>
							</li>
						</ul>

						<input className='checkbox' id='hamburger' type='checkbox' />
						<label htmlFor='hamburger' className='hamburger-lines'>
							<span className='line line1'></span>
							<span className='line line2'></span>
							<span className='line line3'></span>
						</label>

						<ul className='header__nav__list mobile-menu'>
							<li className='header__nav__item'>
								<a className='header__nav__link' href={'/'}>
									{content[lang].header.welcome}
								</a>
							</li>
							<li className='header__nav__item'>
								<a className='header__nav__link' href={'/#about'}>
									{content[lang].header.about}
								</a>
							</li>
							<li className='header__nav__item'>
								<a className='header__nav__link' href={'/#contact'}>
									{content[lang].header.contact}
								</a>
							</li>
							<li className='header__nav__item'>
								<select
									className='header__select'
									defaultValue={lang}
									onChange={(e) => {
										window.localStorage.setItem(
											'lang',
											JSON.stringify(e.target.value),
										);
										setLang(e.target.value);
									}}>
									{/* <option value='en'>English</option> */}
									<option value='uz'>Uzbek</option>
									<option value='ru'>Russian</option>
								</select>
							</li>
						</ul>
					</div>
				</div>
			</header>
		</>
	);
}

export default Header;

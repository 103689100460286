import { useEffect, useState } from 'react';
import './App.scss';
import Header from './components/header/header';
import HeroSection from './components/hero/hero';
import About from './components/about/about';
import Contact from './components/contact/contact';

function App() {
	const [lang, setLang] = useState(
		JSON.parse(window.localStorage.getItem('lang')) || 'uz',
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className='home_bgimg'>
				<Header lang={lang} setLang={setLang} />
				<HeroSection lang={lang} />
			</div>
			<About lang={lang} />
			<Contact lang={lang} />
		</>
	);
}

export default App;

const content = {
   uz: {
      header: {
         welcome: "Asosiy",
         about: "Biz haqimizda",
         contact: "Aloqa"
      },

      hero: {
         title: "VAST TRAVEL",
         text: "SIZNING OPERATORINGIZ!",
         btn: "BUYURTMA QOLDIRISH"
      },

      about: {
         title: "BIZ HAQIMIZDA",
         text: `
            Vast travel kompaniyasi 2017 yilda tashkil etilgan. Va 14 yildan ortiq vaqt davomida u turli yo'nalishlarda sayohat qilish uchun ajoyib imkoniyatni taqdim etadi, turli mamlakatlarga tashriflarni tashkil qiladi va har qanday ta'til turini tanlashga yordam beradi.

            Vast travel xodimlari sizga yuqori xizmatni, qulay reysni tanlashda yordam beradi, shuningdek, dam olish uchun eng yaxshi joy qayerda va qaysi mehmonxonada qolish eng yoqimli ekanligini taklif qiladi.
            
            Biz keng ko'lamli xizmatlarni taklif etamiz: vizalarni rasmiylashtirishdan tortib, individual yoki guruhli turlar, chiptalarni bron qilish, mehmonxonalar, transferlar, ekskursiya va ko'ngilochar dasturlarni tashkil qilishgacha.
            
            Bizning turoperatorlarimiz siz uchun eng yaxshi tanlov tanlashga, eng yaxshi narxlarda va qiziqarli turlarni taqdim etishga, mijozning xohish-istaklarini bajarishga, hujjatlarni rasmiylashtirishda yordam berishga, shuningdek, sayohatning barcha bosqichlarida uning ta'til sifatini kuzatishga tayyor.
         `
      },

      contact: {
         title: "BIZ BILAN BOG‘LANISH",
         inputs: {
            name: "Ismingiz",
            phone: "Telefon raqamingiz",
            theme: "Mavzu",
            message: "Sizning xabaringiz"
         },
         btn: "YUBORISH"
      }
   },

   ru: {
      header: {
         welcome: "Главная",
         about: "О нас",
         contact: "Контакт"
      },

      hero: {
         title: "VAST TRAVEL",
         text: "ВАШ ОПЕРАТОР!",
         btn: "ОФОРМИТЬ ЗАЯВКУ"
      },

      about: {
         title: "О НАС",
         text: `
            Компания Vast travel основана в 2017 году. И уже более 7 лет предоставляет удивительную возможность путешествовать в различных направлениях, организует посещение разнообразных стран и помогает выбрать любой вид отдыха. 

            Сотрудники Vast travel помогут Вам выбрать комфортный сервис, определиться с удобным авиаперелетом, а также подсказать, где лучше всего отдыхается, и в каком из отелей приятней всего остановиться.

            Мы предлагаем широкий спектр услуг: от оформления визы до составления индивидуального или группового тура,  обеспечивая бронирование билетов, отелей, трансферов  и организацию экскурсионной и  развлекательной программ.

            Наши туроператоры   готовы подобрать для Вас оптимальный  вариант,  предоставить выгодные по цене и наиболее интересные туры, выполнить пожелания клиента, оперативно помочь с оформлением документов, а также проследить за качеством его отдыха, проконтролировав  на всех этапах поездки.
         `
      },

      contact: {
         title: "СВЯЗАТЬСЯ С НАМИ",
         inputs: {
            name: "Ваше имя",
            phone: "Ваш номер телефона",
            theme: "Тема",
            message: "Ваше сообщение"
         },
         btn: "ОТПРАВИТЬ"
      }
   },

   en: {
      header: {
         welcome: "Welcome",
         about: "About us",
         contact: "Contact us"
      },

      hero: {
         title: "VAST TRAVEL",
         text: "YOUR OPERATOR!",
         btn: "PLACE AN ORDER"
      },

      about: {
         title: "ABOUT US",
         text: ""
      },

      contact: {
         title: "CONNECT WITH US",
         inputs: {
            name: "Your name",
            phone: "Your phone number",
            theme: "Theme",
            message: "Your message"
         },
         btn: "SEND"
      }
   }
}

export default content
import './about.scss';
import content from '../../Localization/content';

function About({ lang }) {
	return (
		<>
			<section className='about' id='about'>
				<div className='container'>
					<h1 className='about__heading'>{content[lang].about.title}</h1>
				</div>
				<div className='about__line'></div>
				<div className='container'>
					<p className='about__text'>{content[lang].about.text}</p>
				</div>
			</section>

			<section className='map-section'>
				<div className='map__box'>
					<iframe
						className='map'
						src='https://yandex.com/map-widget/v1/?ll=69.251977%2C41.287155&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNTIyNTEzODc2EixPyrt6YmVraXN0b24sIFRvc2hrZW50LCBCb2J1ciBrb8q7Y2hhc2ksIDQwRSIKDQOBikIVCCYlQg%2C%2C&z=16.94'
						loading='lazy'
						style={{ border: 'none' }}></iframe>
				</div>
			</section>
		</>
	);
}

export default About;

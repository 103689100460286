import './hero.scss';
import { useNavigate } from 'react-router-dom';
import content from '../../Localization/content';

function HeroSection({ lang }) {
	const naviagte = useNavigate();

	return (
		<>
			<section className='home-intro'>
				<div className='container'>
					<div className='home-intro__box'>
						<h1 className='home-intro__heading'>
							{content[lang].hero.title}
						</h1>
						<p className='home-intro__text'>{content[lang].hero.text}</p>
						<button className='home-intro__btn'>
							<a href='#contact'>{content[lang].hero.btn}</a>
						</button>
					</div>
				</div>
			</section>
		</>
	);
}

export default HeroSection;

import './contact.scss';
import content from '../../Localization/content';

function Contact({ lang }) {
	const sendMessageBot = (e) => {
		e.preventDefault();
		const { name, phone, theme, message } = e.target.elements;
		const token = '6796415247:AAGSleocvLr0bn7nBi5R-Nq0USRA3-Xtwok';
		const chat_id = -1001673699280;
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const day = String(today.getDate()).padStart(2, '0');
		const hours = String(today.getHours()).padStart(2, '0');
		const minutes = String(today.getMinutes()).padStart(2, '0');
		const seconds = String(today.getSeconds()).padStart(2, '0');
		const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		const text = `
            Ismi: ${name.value}%0ATelefon raqami: ${phone.value}%0AMavzu: ${theme.value}%0AXabari: ${message.value}%0ASana${formattedDateTime}
        `;

		const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${text}`;

		let api = new XMLHttpRequest();
		api.open('GET', url, true);
		api.send();

		name.value = null;
		phone.value = null;
		theme.value = null;
		message.value = null;
	};

	return (
		<>
			<section className='contact' id='contact'>
				<div className='container container_bg'>
					<h2 className='contact__heading'>
						{content[lang].contact.title}
					</h2>
				</div>

				<div className='container'>
					<form onSubmit={sendMessageBot}>
						<div className='input__box'>
							<input
								className='name'
								type='text'
								name='name'
								placeholder={content[lang].contact.inputs.name}
								required
							/>
							<input
								className='phone'
								type='text'
								name='phone'
								placeholder={content[lang].contact.inputs.phone}
								required
							/>
							<input
								className='theme'
								type='text'
								name='theme'
								placeholder={content[lang].contact.inputs.theme}
								required
							/>
						</div>

						<textarea
							name='message'
							cols='30'
							rows='15'
							required
							placeholder={
								content[lang].contact.inputs.message
							}></textarea>

						<button className='contact__btn'>
							{content[lang].contact.btn}
						</button>
					</form>

					<div className='contact__box'>
						<p>Toshkent shahar, Yakkasaroy tuman, Bobur 40E uy</p>
						<p>
							<a href='tel:+998903442200'>+998 90 344 22 00</a>
						</p>
						<p>
							<a href='mailto:infovastuz@gmail.com'>
								infovastuz@gmail.com
							</a>
						</p>
					</div>
				</div>
			</section>
		</>
	);
}

export default Contact;
